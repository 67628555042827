<template>
  <div style="position: relative; overflow: hidden">
    <div style="position: absolute; width: 1920px; left: 50%;transform: translateX(-50%); top:0; bottom: 0">
      <img src="/images/waves/bigWave.svg" class="bigWave">
      <img src="/images/waves/blob1.svg" class="blob1">
      <img src="/images/waves/bigBoy.svg" class="bigBoy">
    </div>
    <Header style="z-index: 2; position: relative"></Header>
     <section class="section gapBetween" style="z-index: 2; position: relative" id="socialMedia" data-aos="fade-up">
      <div class="container has-text-centered">
        <h1 class="headText">Social Media</h1>
        <h2 class="mail mt-4">Śledź mnie na bieżąco w moich Social Mediach!</h2>
        <div class="iconsContainer mt-6">
          <a :href="getEnv('YOUTUBE')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-youtube"></font-awesome-icon>
          </a>
          <a :href="getEnv('TWITCH')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-twitch"></font-awesome-icon>
          </a>
          <a :href="getEnv('DISCORD')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-discord"></font-awesome-icon>
          </a>
          <a :href="getEnv('INSTAGRAM')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-instagram"></font-awesome-icon>
          </a>
          <a :href="getEnv('TIKTOK')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-tiktok"></font-awesome-icon>
          </a>
          <a :href="getEnv('FACEBOOK')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-facebook"></font-awesome-icon>
          </a>
        </div>
      </div>
    </section>
    <section class="section gapBetween" style="z-index: 2; position: relative" data-aos="fade-up">
      <div class="container has-text-centered">
        <h1 class="headText">endreq</h1>
        <h2 class="mail mt-4">Poznaj i śledź mnie od strony Muzycznej!</h2>
        <div class="iconsContainer mt-6">
          <a :href="getEnv('YOUTUBEE')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-youtube"></font-awesome-icon>
          </a>
          <a :href="getEnv('SOUNDCLOUD')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-soundcloud"></font-awesome-icon>
          </a>
          <a :href="getEnv('SPOTIFY')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-spotify"></font-awesome-icon>
          </a>
          <a :href="getEnv('APPLE')" target="_blank">
            <font-awesome-icon icon="fa-brands fa-apple"></font-awesome-icon>
          </a>
        </div>
      </div>
    </section>
    <section class="section gapBetween" style="z-index: 2; position: relative">
      <div class="container has-text-centered">
        <h1 class="headText">Najnowsze aktywności</h1>
        <div class="socialContainer mt-5">
          <social-box :image="socialMedia.spotify.image" :link="socialMedia.spotify.link" type="spotify" data-aos="fade-up-right"></social-box>
          <social-box :image="socialMedia.youtube.image" :link="socialMedia.youtube.link" type="youtube" data-aos="fade-up"></social-box>
          <social-box :image="socialMedia.instagram.image" :link="socialMedia.instagram.link" type="instagram" data-aos="fade-up-left"></social-box>
        </div>
      </div>
    </section>
    <section class="section gapBetween" style="z-index: 2; position: relative" data-aos="fade-up">
      <div class="container has-text-centered">
        <h1 class="headText">Kontakt</h1>
        <h2 class="mail mt-4">endreqbiznes@gmail.com</h2>
        </div>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
      <br>
    </section>
    <footer style="z-index: 2; position: relative">
      <div class="container">
        <hr class="line">
        <div class="footter" style="padding-top: 2rem; padding-bottom: 3.25rem">
          <span class="footerText">xendreq.pl © 2024 - Wszelkie prawa zastrzeżone.</span>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import Header from "@/components/Header";
import SocialBox from "@/components/SocialBox";
export default {
  name: 'Home',
  components: {
    SocialBox,
    Header
  }, created() {
    fetch("/data.json")
        .then(response => response.json())
        .then(data => this.socialMedia = data)
  }, data() {
    return {
      socialMedia: {}
    }
  }, methods: {
    getEnv(env) {
      return process.env['VUE_APP_' + env];
    }
  }
}
</script>

<style scoped lang="scss">
@import "~bulma/sass/utilities/mixins";
.headText {
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  letter-spacing: 8px;
  color: var(--primary);
  font-weight: 600;
  font-family: "Poppins",sans-serif;
  text-align: center;
}
.mail {
  font-size: clamp(1.1rem, 5vw, 1.5rem);
  letter-spacing: 6px;
  color: #fefefe;
  font-weight: 500;
  font-family: "Poppins",sans-serif;
  text-align: center;
}
.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 60px;
  @include touch {
    gap: 30px
  }
}
.iconsContainer > * {
  font-size: 3rem;
  color: #fff;
  transition: color .5s;
  cursor: pointer;
  @include touch {
    font-size: 2rem;
  }
}
.iconsContainer > *:hover {
  color: var(--secondary);
}
.socialContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 50px;
}
.line {
  border: 1px solid #4d2763;
  margin: 0;
}
.footerText {
  font-weight: 600;
  font-size: 1rem;
  color: #fff;
  @include mobile {
    font-size: 1rem;
    text-align: center;
  }
}
.footter {
  display: flex;
  align-items: center;
  justify-content: center;
  @include mobile {
    flex-direction: column;
    gap: 10px;
  }
  @include tablet-only {
    padding: 0 16px;
  }
}
.footerLogo {
  margin-left: auto;
  @include mobile {
    margin: 0 auto;
    height: 40px;
  }
}
.bigWave {
  position: absolute;
  right: 100px;
  top: -350px;
  z-index: 1;
}
.blob1 {
  position: absolute;
  top: 70vh;
  right: -150px;
  z-index: 1;
}
.bigBoy {
  position: absolute;
  bottom: -200px;
  right: 200px;
  z-index: 1;
  transform: scale(1.3);
  @include touch {
    bottom: -400px;
  }
}
.gapBetween {
  margin-top: 200px;
  @include touch {
    margin-top: 100px;
  }
}
</style>
